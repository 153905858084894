<template>
  <div class="auto-complete" v-if="mode==='edit'">
    <label class="text-left font-label">{{ inputLabel }}</label>
    <v-autocomplete
      :value="value"
      :multiple="multiple"
      hide-selected
      hide-no-data
      :search-input.sync="search"
      v-bind="$attrs"
      v-on="$listeners"
      @input="logicalUpdate"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-chip
            label
            small
          >
            {{ search }}
          </v-chip>
        </v-list-item>
      </template>
      <template v-slot:selection="{ attrs, item, parent, selected }">
        <v-chip
          v-bind="attrs"
          :input-value="selected"
          color="#e5e7fa"
          label
          small
        >
          <span class="pr-2 chip-text notranslate">
            {{ item === Object(item) ? item[itemText] : item }}
          </span>
          <v-icon
            v-if="!notClearable"
            class="close-icon"
            small
            @click="parent.selectItem(item)"
          >close</v-icon>
        </v-chip>
      </template>
      <template v-slot:item="{ index, item }">
        <v-chip
          dark
          label
          small
          color="#e5e7fa"
        >
          <span class="chip-text notranslate">
            {{ item[itemText] }}
          </span>
        </v-chip>
        <v-spacer></v-spacer>
      </template>
    </v-autocomplete>
</div>
<div v-else>
  <slot />
</div>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    mode: {
      type: String,
      default: 'edit',
    },
    inputLabel: {
      type: String,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    notClearable: {
      type: Boolean,
      default: false,
    },
    value: {
    },
  },
  computed: {
    itemText() {
      if (this.$attrs['item-text']) {
        return this.$attrs['item-text'];
      }
      return 'text';
    },
    itemValue() {
      if (this.$attrs['item-value']) {
        return this.$attrs['item-value'];
      }
      return 'value';
    },
  },
  data: () => ({
    search: null,
  }),
  methods: {
    logicalUpdate() {
      this.search = '';
    },
  },
};
</script>

<style lang="scss" scoped>
  .auto-complete {
    margin-bottom: 20px;
  }
  .chip-text {
    color: $primary2;
    width: fit-content;
    max-width: 550px;
  }
  .close-icon {
    color: $primary2 !important;
  }
  ::v-deep .v-text-field {
    margin-top: 0;
  }
</style>
