<template>
  <div class="avatar-round">
    <v-avatar
      :size="size"
      style="background-color: #C0C0C0;border: 2px solid #FFFFFF;"
      class="ma-0 d-flex avatar cursor-pointer"
      @click="$emit('openPopup')"
    >
      <img
      v-if="avatar"
      :src="getOptimizedS3ImageURL(avatar, { width: size, height: size })" />
      <div class="name-initial" :style="textStyle" v-else>
        {{ name }}
      </div>
    </v-avatar>
  </div>
</template>

<script>
import { getOptimizedS3ImageURL } from '@/helpers/';

export default {
  name: 'Avatar',
  props: {
    avatar: {
      type: String,
      default: null,
    },
    size: {
      type: [String, Number],
      default: 50,
    },
    textStyle: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
  },
  methods: {
    getOptimizedS3ImageURL,
  },
};
</script>
