<!-- eslint-disable max-len -->
<template>
    <svg width="16" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.0001 8V7.313C19.9982 5.90447 19.4379 4.55417 18.4419 3.55819C17.4459 2.56221 16.0956 2.00185 14.6871 2H13.5001V0H10.5001V2H9.31311C8.03759 1.9981 6.80396 2.45514 5.83757 3.28763C4.87118 4.12013 4.23654 5.2725 4.04958 6.53425C3.86262 7.796 4.13583 9.08289 4.81929 10.1599C5.50275 11.2368 6.55085 12.0319 7.77211 12.4L10.5001 13.146V19H9.31311C8.69991 18.9992 8.11205 18.7553 7.67845 18.3217C7.24485 17.8881 7.00091 17.3002 7.00011 16.687V16H4.00012V16.687C4.00197 18.0955 4.56232 19.4458 5.5583 20.4418C6.55428 21.4378 7.90459 21.9981 9.31311 22H10.5001V24H13.5001V22H14.6871C15.9626 22.0019 17.1962 21.5449 18.1626 20.7124C19.129 19.8799 19.7637 18.7275 19.9506 17.4657C20.1376 16.204 19.8644 14.9171 19.1809 13.8401C18.4974 12.7632 17.4494 11.9681 16.2281 11.6L13.5001 10.856V5H14.6871C15.3003 5.00079 15.8881 5.24474 16.3217 5.67834C16.7553 6.11194 16.9993 6.6998 17.0001 7.313V8H20.0001ZM15.3821 14.479C15.9096 14.6452 16.3602 14.995 16.6521 15.4648C16.944 15.9346 17.058 16.4935 16.9733 17.0401C16.8887 17.5867 16.6111 18.0849 16.1907 18.4444C15.7704 18.804 15.2352 19.0011 14.6821 19H13.5001V13.965L15.3821 14.479ZM10.5001 10.035L8.61811 9.521C8.09098 9.35488 7.64071 9.00557 7.34878 8.53627C7.05684 8.06697 6.94252 7.50868 7.0265 6.9624C7.11048 6.41613 7.3872 5.91795 7.80662 5.55801C8.22603 5.19806 8.76042 5.00012 9.31311 5H10.5001V10.035Z" :fill="color"/>
    </svg>
</template>

<script>
export default {
  name: 'dollar',
  props: {
    color: {
      type: String,
      default: '#262626',
    },
    width: {
      type: String,
      default: '16',
    },
    height: {
      type: String,
      default: '16',
    },
  },
};
</script>
