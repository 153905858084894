<template>
    <v-row align="center" class="custom-time-picker">
      <v-combobox
        v-model="selectTime"
        dense
        :error-messages="errorMes"
        :items="options"
        @change="setValue"
        v-bind="$attrs"
        v-on="$listeners"
        class="mt-0 time-picker-combo"
        >
      </v-combobox>
    </v-row>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';

export default {
  name: 'TimePicker',
  data() {
    return {
      defaultFormat: 'hh:mm a',
      hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      minutes: [0, 30],
      meridian: ['am', 'pm'],
    };
  },
  props: {
    timeErr: {
      type: String,
      default: '',
    },
    time: {
      type: String,
      default: '08:00:00',
    },
    format: {
      type: String,
      default: 'HH:mm:ss',
    },
    initial: {
      type: Boolean,
      default: true,
    },
    parentComponent: {
      type: String,
      default: '',
    },
    optionsAfter: {
      type: String,
      default: '',
    },
  },
  methods: {
    setValue(val) {
      const time = moment(val, [this.defaultFormat]).format(this.format);
      if (time !== 'Invalid date' && this.meridian.includes(moment(time, [this.format]).format('a'))) {
        this.$emit('onTimeChange', time);
        this.$emit('onErrorChage', false);
      } else {
        this.$emit('onTimeChange', time);
        this.$emit('onErrorChage', true);
      }
    },
  },
  computed: {
    errorMes() {
      let { time } = this;
      if (this.parentComponent === 'sweepForm') {
        if (this.initial) {
          return '';
        }
        return this.timeErr;
      }
      time = moment(time, [this.format]).format(this.defaultFormat);
      if (time !== 'Invalid date') {
        return '';
      }
      return 'Invalid Time';
    },
    selectTime() {
      let { time } = this;
      time = moment(time, [this.format]).format(this.defaultFormat);
      if (time !== 'Invalid date') {
        this.$emit('onErrorChage', false);
        return time;
      }
      this.$emit('onErrorChage', true);
      return '';
    },
    optionTime() {
      let { optionsAfter } = this;
      optionsAfter = moment(optionsAfter, [this.format]).add(30, 'minutes').format(this.defaultFormat);
      if (optionsAfter !== 'Invalid date') {
        return optionsAfter;
      }
      return '';
    },
    options() {
      const timeOptions = [];
      const {
        hours,
        minutes,
        meridian,
      } = this;
      let arr = [];
      _.map(hours, (hour) => {
        _.map(minutes, (min) => {
          _.map(meridian, (merid) => {
            const minu = min;
            const time = `${hour.toString().padStart(2, '0')}:${minu.toString().padStart(2, '0')} ${merid}`;
            timeOptions.push(time);
          });
        });
      });
      arr = timeOptions.sort((a, b) => new Date(`1970/01/01  ${a}`) - new Date(`1970/01/01  ${b}`));
      if (this.optionsAfter) {
        const data = arr.splice(arr.indexOf(this.optionTime), arr.length);
        return data.sort((a, b) => new Date(`1970/01/01  ${a}`) - new Date(`1970/01/01  ${b}`));
      }
      return arr;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-time-picker {
  margin: unset;
  // max-width: 160px;
  ::v-deep .v-autocomplete.v-input > .v-input__control > .v-input__slot {
    padding: 5px;
  }
  ::v-deep :not(.error--text).v-text-field > .v-input__control > .v-input__slot:before {
    border-color: $border2;
  }
  ::v-deep :not(.error--text).v-text-field > .v-input__control > .v-input__slot {
    .mdi-clock-outline {
      color: rgba(0, 0, 0, 0.54) !important;
    }
  }
}
.time-menu {
  max-height: 300px;
}
::v-deep {
  .v-input__icon--append {
    align-items: flex-end;
    cursor: pointer !important;
    .v-icon {
      transform: none !important;
    }
    .v-icon.mdi-clock-outline {
      transform: rotate(240deg) !important;
    }
  }
  .v-menu__content {
      top: 170px !important;
  }
  .v-list--dense .v-list-item .v-list-item__title {
    font-size: 14px;
    font-weight: 400;
    font-family: $fontFamily1 !important;
    color: #3c4043;
  }
  .theme--light.v-list-item .v-list-item__content .v-list-item__title {
    font-size: 14px;
    font-weight: 400;
    font-family: $fontFamily1;
    color: #3c4043 !important;
  }
}
</style>
