<!-- eslint-disable max-len -->
<template>
<svg width="18" height="18" viewBox="0 0 18 18" :fill="color" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2740_33337)">
<path d="M3.375 13.125H1.5C1.10218 13.125 0.720644 13.283 0.43934 13.5643C0.158035 13.8456 0 14.2272 0 14.625L0 16.5C0 16.8978 0.158035 17.2794 0.43934 17.5607C0.720644 17.842 1.10218 18 1.5 18H3.375C3.77282 18 4.15436 17.842 4.43566 17.5607C4.71696 17.2794 4.875 16.8978 4.875 16.5V14.625C4.875 14.2272 4.71696 13.8456 4.43566 13.5643C4.15436 13.283 3.77282 13.125 3.375 13.125ZM3.375 16.5H1.5V14.625H3.375V16.5Z" :fill="color"/>
<path d="M16.5 13.125H14.625C14.2272 13.125 13.8456 13.283 13.5643 13.5643C13.283 13.8456 13.125 14.2272 13.125 14.625V16.5C13.125 16.8978 13.283 17.2794 13.5643 17.5607C13.8456 17.842 14.2272 18 14.625 18H16.5C16.8978 18 17.2794 17.842 17.5607 17.5607C17.842 17.2794 18 16.8978 18 16.5V14.625C18 14.2272 17.842 13.8456 17.5607 13.5643C17.2794 13.283 16.8978 13.125 16.5 13.125ZM16.5 16.5H14.625V14.625H16.5V16.5Z" :fill="color"/>
<path d="M3.375 6.5625H1.5C1.10218 6.5625 0.720644 6.72054 0.43934 7.00184C0.158035 7.28314 0 7.66468 0 8.0625L0 9.9375C0 10.3353 0.158035 10.7169 0.43934 10.9982C0.720644 11.2795 1.10218 11.4375 1.5 11.4375H3.375C3.77282 11.4375 4.15436 11.2795 4.43566 10.9982C4.71696 10.7169 4.875 10.3353 4.875 9.9375V8.0625C4.875 7.66468 4.71696 7.28314 4.43566 7.00184C4.15436 6.72054 3.77282 6.5625 3.375 6.5625ZM3.375 9.9375H1.5V8.0625H3.375V9.9375Z" :fill="color"/>
<path d="M16.5 6.5625H14.625C14.2272 6.5625 13.8456 6.72054 13.5643 7.00184C13.283 7.28314 13.125 7.66468 13.125 8.0625V9.9375C13.125 10.3353 13.283 10.7169 13.5643 10.9982C13.8456 11.2795 14.2272 11.4375 14.625 11.4375H16.5C16.8978 11.4375 17.2794 11.2795 17.5607 10.9982C17.842 10.7169 18 10.3353 18 9.9375V8.0625C18 7.66468 17.842 7.28314 17.5607 7.00184C17.2794 6.72054 16.8978 6.5625 16.5 6.5625ZM16.5 9.9375H14.625V8.0625H16.5V9.9375Z" :fill="color"/>
<path d="M3.375 0H1.5C1.10218 0 0.720644 0.158035 0.43934 0.43934C0.158035 0.720644 0 1.10218 0 1.5L0 3.375C0 3.77282 0.158035 4.15436 0.43934 4.43566C0.720644 4.71696 1.10218 4.875 1.5 4.875H3.375C3.77282 4.875 4.15436 4.71696 4.43566 4.43566C4.71696 4.15436 4.875 3.77282 4.875 3.375V1.5C4.875 1.10218 4.71696 0.720644 4.43566 0.43934C4.15436 0.158035 3.77282 0 3.375 0V0ZM3.375 3.375H1.5V1.5H3.375V3.375Z" :fill="color"/>
<path d="M9.9375 13.125H8.0625C7.66468 13.125 7.28314 13.283 7.00184 13.5643C6.72054 13.8456 6.5625 14.2272 6.5625 14.625V16.5C6.5625 16.8978 6.72054 17.2794 7.00184 17.5607C7.28314 17.842 7.66468 18 8.0625 18H9.9375C10.3353 18 10.7169 17.842 10.9982 17.5607C11.2795 17.2794 11.4375 16.8978 11.4375 16.5V14.625C11.4375 14.2272 11.2795 13.8456 10.9982 13.5643C10.7169 13.283 10.3353 13.125 9.9375 13.125ZM9.9375 16.5H8.0625V14.625H9.9375V16.5Z" :fill="color"/>
<path d="M9.9375 6.5625H8.0625C7.66468 6.5625 7.28314 6.72054 7.00184 7.00184C6.72054 7.28314 6.5625 7.66468 6.5625 8.0625V9.9375C6.5625 10.3353 6.72054 10.7169 7.00184 10.9982C7.28314 11.2795 7.66468 11.4375 8.0625 11.4375H9.9375C10.3353 11.4375 10.7169 11.2795 10.9982 10.9982C11.2795 10.7169 11.4375 10.3353 11.4375 9.9375V8.0625C11.4375 7.66468 11.2795 7.28314 10.9982 7.00184C10.7169 6.72054 10.3353 6.5625 9.9375 6.5625ZM9.9375 9.9375H8.0625V8.0625H9.9375V9.9375Z" :fill="color"/>
<path d="M9.9375 0H8.0625C7.66468 0 7.28314 0.158035 7.00184 0.43934C6.72054 0.720644 6.5625 1.10218 6.5625 1.5V3.375C6.5625 3.77282 6.72054 4.15436 7.00184 4.43566C7.28314 4.71696 7.66468 4.875 8.0625 4.875H9.9375C10.3353 4.875 10.7169 4.71696 10.9982 4.43566C11.2795 4.15436 11.4375 3.77282 11.4375 3.375V1.5C11.4375 1.10218 11.2795 0.720644 10.9982 0.43934C10.7169 0.158035 10.3353 0 9.9375 0V0ZM9.9375 3.375H8.0625V1.5H9.9375V3.375Z" :fill="color"/>
<path d="M16.5 0H14.625C14.2272 0 13.8456 0.158035 13.5643 0.43934C13.283 0.720644 13.125 1.10218 13.125 1.5V3.375C13.125 3.77282 13.283 4.15436 13.5643 4.43566C13.8456 4.71696 14.2272 4.875 14.625 4.875H16.5C16.8978 4.875 17.2794 4.71696 17.5607 4.43566C17.842 4.15436 18 3.77282 18 3.375V1.5C18 1.10218 17.842 0.720644 17.5607 0.43934C17.2794 0.158035 16.8978 0 16.5 0V0ZM16.5 3.375H14.625V1.5H16.5V3.375Z" :fill="color"/>
</g>
<defs>
<clipPath id="clip0_2740_33337">
<rect width="18" height="18" fill="white"/>
</clipPath>
</defs>
</svg>
</template>

<script>
export default {
  name: 'Grid',
  props: {
    color: {
      type: String,
      default: 'black',
    },
  },
};
</script>
